import { marked } from "marked"
import { FC, useEffect, useState } from "react"
import DOMPurify from "dompurify"

type MarkdownViewerProps = {
    markdown: string
}
export const MarkdownViewer: FC<MarkdownViewerProps> = ({ markdown }) => {
    const [sanitizedHtml, setSanitizedHtml] = useState('')

    useEffect(() => {
        const processMarkdown = async () => {
            const rawHtml = await marked(markdown)
            const safeHtml = DOMPurify.sanitize(rawHtml)

            setSanitizedHtml(safeHtml)
        }

        processMarkdown()
    }, [markdown])
    const rawHtml = marked(markdown)

    return (
        <div
            className="markdown-viewer"
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        />
    )
}