export const roundWithScale = (value: number, scale: number) => {
    return Math.round(value * 10 ** scale) / 10 ** scale
}

// 中央値を求める
export const findMedian = (arr: (number | string | null)[]): number | null => {
    // 0を除外して有効な値のみを残す
    const filteredArr = arr.filter(num => Number(num) > 0)

    // 配列が空の場合はnullを返す
    if (filteredArr.length === 0) return null

    // 昇順にソート
    const sortedArr = filteredArr.sort((a, b) => Number(a) - Number(b))

    // 中央値を計算
    const mid = Math.floor(sortedArr.length / 2)

    if (sortedArr.length % 2 === 0) {
        // 偶数個の場合は中央の2つの平均
        return (Number(sortedArr[mid - 1]) + Number(sortedArr[mid])) / 2
    } else {
        // 奇数個の場合は中央の値
        return Number(sortedArr[mid])
    }
}