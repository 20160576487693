import { useAdminSiteSelectOptions } from "../../Hooks/Admin/Site"
import { useSiteSelectOptions } from "../../Hooks/User/Site"
import { Select } from "../Ui/Form"

interface SiteSelectProps {
    name: string
    placeholder?: string
    onChange?: (newValue: number | string) => void
    className?: string 
}
export const SiteSelect = ({ name, placeholder='対象のサイトを選択してください', onChange, className }: SiteSelectProps) => {
    const { options, isLoading, isError } = useSiteSelectOptions()

    if (isLoading) return <span className="text-xs">利用可能なサイトを取得中です...</span>
    if (isError) {
        window.location.href = `/sites`
    }

    return (
        <div className={ className ? className : `inline-block w-56` }>
            <Select name={name} options={options} placeholder={placeholder} onChange={onChange} />
        </div>
    )
}

export const AdminSiteSelect = ({ name, placeholder='対象のサイトを選択してください', onChange, className }: SiteSelectProps) => {
    const { options, isLoading, isError } = useAdminSiteSelectOptions()

    if (isLoading) return <span className="text-xs">利用可能なサイトを取得中です...</span>
    if (isError) {
        <span>サイトが取得できませんでした、システム管理者にお問い合わせ下さい</span>
    }

    return (
        <div className={ className ? className : `inline-block w-56` }>
            <Select name={name} options={options} placeholder={placeholder} onChange={onChange} />
        </div>
    )
}